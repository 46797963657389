import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import * as cookies from 'js-cookie';
import { ParallaxProvider } from 'react-scroll-parallax';
import { RichTextBlock } from 'prismic-reactjs';
import { Header, Nav, Footer, Cookies } from '@/components';
import theme from '@/styles/theme';


interface Props {
  children: React.ReactNode;
  header: {
    data: {
      email: {
        raw: RichTextBlock[];
      }
      phone: {
        raw: RichTextBlock[];
      }
      socials: {
        social: string;
        link: {
          url: string;
        }
      }[];
      navigation: {
        nav_link: {
          raw: RichTextBlock[];
        }
      }[]
    }
  }
  selectLang: {
    lang: string;
    uid: string;
    alternate_languages: {
      lang: string;
      uid: string;
    }[];
  };
  isHome?: boolean;
}

export const Layout = ({ children, header, selectLang, isHome }: Props): JSX.Element => {
  const { lang } = selectLang;
  const [open, setOpen] = useState<boolean>(false);

  const toggleNav = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const cookie = cookies.get('globalelectrics_legal');

  return (
      <ChakraProvider theme={theme} >
        <RecoilRoot>
          <ParallaxProvider>
            <Header header={header.data} lang={lang} />
            <Nav open={open} toggleNav={toggleNav} navigation={header.data.navigation} selectLang={selectLang} isHome={isHome} />
            <main role="main">{children}</main>
            <Footer lang={lang} />
            {!cookie && <Cookies lang={lang} />}
          </ParallaxProvider>
        </RecoilRoot>
      </ChakraProvider>
  );
};

