import React from 'react';
import { css } from '@emotion/react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
const linkResolver = require('@/prismic/resolver')
import { htmlSerializer } from './htmlSerializer';
import theme from '@/styles/theme';

interface PrismicComponentProps {
  content: RichTextBlock[];
}
const PrismicComponent: React.FC<PrismicComponentProps> = ({ content, ...props }) => {
  return (
    <div
      css={css`
        b,
        strong {
          font-weight: 700;
        }
        ​ em {
          font-style: italic;
        }
      `}
      {...props}
    >
      <RichText
        render={content}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializer}
      />
    </div>
  );
};

export default PrismicComponent;
