import React, { SetStateAction, Dispatch, useEffect } from 'react';
import Img from 'gatsby-image';
import { FluidObject } from 'gatsby-image';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import styled from '@emotion/styled';

import mq from '@/styles/mq';


const Root = styled(motion.div)`
    padding: 2rem 0;
    box-sizing: border-box;
    margin: auto;
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
    column-gap: 1rem;

    .gatsby-image-wrapper{
        cursor: pointer;
        background-color: #eee;
        display: inline-block;
        width: 100%;
        object-fit: fill;
        border-radius: 5px;
        margin-bottom: 1rem;

        transition: .5s;
        &:hover {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        -webkit-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        -moz-box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
        }
    } 

    ${mq.mqMax('lg')}{
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }
    ${mq.mqMax('sm')}{
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
`;

interface Props {
    images: {
        images_gallery: {
            alt: string;
            fluid: FluidObject;
        };
    }[];
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIndex: (i: number) => void;
    setImages: (images: any) => void;
}

export const Gallery = ({ images, setOpen, setIndex, setImages }: Props): JSX.Element => {
    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });
    
    const variants = {
        visible: (i: number) => ({
            opacity: 1,
            transition: {
                delay: i * 0.1,
            },
        }),
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [inView])


    return (
        <Root ref={ref}>
            {images.map((img, i) => {
                return(
                    <motion.div onClick={() => { setOpen(true); setIndex(i); setImages(images) }} 
                        key={i}
                        initial="hidden"
                        custom={i}
                        animate={controls}
                        variants={variants}
                    >
                        <Img fluid={img.images_gallery.fluid} alt={img.images_gallery.alt} key={i} />
                    </motion.div>
                )
            })}
        </Root>
    );
};

