import React from 'react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';

import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react';
import theme from '@/styles/theme';

interface Props extends TextareaProps {
    name: string;
}

const TextareaBase = ({ name, ...props }: Props): JSX.Element => {
    const { register } = useFormContext<FormData>();

    return (
        <ChakraTextarea
            {...props}
            //@ts-ignore
            {...register(name, { required: "Ce champ est requis" })}
            name={name}
            resize="vertical"
            variant="unstyled"
        />
    );
};

export const Textarea = styled(TextareaBase)`
  width: 100%;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${theme.colors.gray500};
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-bottom: 0.5em;
  background-color: transparent;
  border-radius: 0;
  min-height: 150px;
  box-sizing: border-box;
  &:focus {
    border: 1px solid;
    border-color: ${theme.colors.colorPrimary};
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

