import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

interface MetaName {
  content: string;
  name: string;
  property?: undefined;
}

interface MetaProp {
  content: string;
  name?: undefined;
  property: string;
}

interface Props {
  description?: string;
  lang?: string;
  meta?: MetaName[] | MetaProp[];
  title: string;
  url: string;
  keywords: string;
  alternate?: {
    lang: string;
    url: string;
  }[];
}

export const SEO = ({
  description,
  lang = `fr`,
  meta = [],
  title,
  url,
  keywords,
  alternate
}: Props): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const renderLinkAlternate = () => {
    if(alternate !== undefined){
      return alternate?.map(data => (
        <link rel="alternate" hrefLang={data.lang} href={`https://www.globalelectrics.be/${data.url}`} data-react-helmet="true" />
      ))
    }
    return;
  }
  
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}   
    >
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`https://www.globalelectrics.be/${url}`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.globalelectrics.be" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:site_name" content="Global Electrics" />
      <link rel="alternate" hrefLang="x-default" href="https://www.globalelectrics.be" data-react-helmet="true" />
      {renderLinkAlternate()}
      {/* <link
        href="https://api.tiles.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css"
        rel="stylesheet"
      />
      <link href='https://api.mapbox.com/mapbox-gl-js/v2.2.0/mapbox-gl.css' rel='stylesheet' /> */}
      
    </Helmet>
  );
};
