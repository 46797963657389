import React from 'react';
import styled from '@emotion/styled';

import H1 from './h1';
import H2 from './h2';
import H3 from './h3';
import H4 from './h4';
import Text from './text';

type VariantType = `h1` | `h2` | `h3` | `h4`;

const setTypographyStyle = (variant?: VariantType) => {
  switch (variant) {
    case `h1`:
      return H1;
    case `h2`:
      return H2;
    case `h3`:
      return H3;
    case `h4`:
      return H4;
    default:
      return Text;
  }
};

interface TypographyProps {
  children: React.ReactNode;
  as?: React.ElementType;
  variant?: VariantType;
}

const Root = styled.div<TypographyProps>`
  ${({ variant }) => setTypographyStyle(variant)};
`;

export const Typography = ({
  children,
  as = 'div',
  ...props
}: TypographyProps): JSX.Element => {
  return (
    <Root as={as} {...props}>
      {children}
    </Root>
  );
};
