import React from 'react';
import styled from '@emotion/styled';

import mq from '@/styles/mq';

import useImages from '@/hooks/useImages';

const Root = styled.div`
  display: flex;
  margin-left: 4rem;
  .gatsby-image-wrapper{
    margin: 0 10px;
  }
  ${mq.mqMax('xl')}{
        a{
            font-size: 0.9rem !important;
        }
        .gatsby-image-wrapper{
            width: 15px;
            height: 15px;
            margin: 0 5px;

        }
  }
  ${mq.mqMax('lg')}{
    margin: 0;
  }
  ${mq.mqMax('sm')}{
    margin-top: 10px;
  }
  
`;


interface Props {
  socials: {
    social: string;
    link: {
      url: string;
    }
  }[];
}

export const HeaderSocials = ({ socials }: Props): JSX.Element => {
  const useSocials = useImages();

  const renderSocials = () => 
    socials?.map((social, i) => (
      <a href={social?.link?.url} key={i} target="_blank">
        {useSocials(social?.social?.toLowerCase(), '20px', '20px')}
      </a>
    ))

    return (
      <Root>
        {renderSocials()}
      </Root>
    );
};

