import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import 'mapbox-gl/dist/mapbox-gl.css';

import mq from '@/styles/mq';

import styled from '@emotion/styled';


mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = `${process.env.GATSBY_MAPBOX_KEY}`;


const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .map-container {
    width: 100%;
    height: 450px;
  }

  ${mq.mqMax('md')}{
    .map-container {
        height: 400px;
    }
  }
  ${mq.mqMax('md')}{
    .map-container {
        height: 300px;
    }
  }
`;

const MapBoxContainer = styled.div`
    width: 100%;
`;


export const Mapbox = (): JSX.Element => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [lng] = useState(4.168757651645819);
    const [lat] = useState(50.950607417739135);
    const [zoom] = useState(12);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainer.current as HTMLElement | string,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
            minZoom: 7,
            maxZoom: 17,
        });
        new mapboxgl.Marker().setLngLat([4.168757651645819, 50.950607417739135]).addTo(map);
        map.addControl(new mapboxgl.NavigationControl());
        return () => map.remove();
    }, []);

    return (
        <Root>
            <MapBoxContainer>
                <div className="map-container" ref={mapContainer} />
            </MapBoxContainer>
        </Root>
    );
};

