

// @ts-ignore
const linkResolver = (doc) => {
  const lang = doc.lang.split('-')[0];

  switch (doc.type) {
    case `homepage`:
      return `/${lang}`;
    case `electricitegeneralepage`:
      return `/${lang}/${doc.uid}`;
    case `depannagepage`:
      return `/${lang}/${doc.uid}`;
    case `entretienpage`:
      return `/${lang}/${doc.uid}`;
    case `eclairagepage`:
      return `/${lang}/${doc.uid}`;
    case `miseenconformitepage`:
      return `/${lang}/${doc.uid}`;
    case `donneespage`:
      return `/${lang}/${doc.uid}`;
    case `realisationspage`:
      return `/${lang}/${doc.uid}`;
    case `cgvpage`:
      return `/${lang}/${doc.uid}`;
    case `cookiespage`:
      return `/${lang}/${doc.uid}`;
    case `mentionspage`:
      return `/${lang}/${doc.uid}`;
    case `contactpage`:
      return `/${lang}/${doc.uid}`;
    default:
      return `/`;
  }
}

module.exports = linkResolver;
