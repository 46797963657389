import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';
import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';

import useImages from '@/hooks/useImages';
import theme from '@/styles/theme';


const Root = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.colorThird};
    h2{
        color: ${theme.colors.colorGreen} !important;
    }
    h2, p{
        color: white;
    }
    a{
        text-decoration: underline;
        transition: .2s;
        &:hover{
            color: lightgray;

        }
    }
`;

const FooterContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    padding: 3rem;
    > div{
        margin: 0 1.5rem 0 0;
    }
    ${mq.mqMax('md')}{
        flex-wrap: wrap;
        padding: 1.5rem;
        p{
            margin-bottom: 0.5rem;
        }
    }

`

const FooterDescription = styled.div`
    width: 50%;
    ${mq.mqMax('md')}{
        width: 100%;
    }
`;
const FooterServices = styled.div`
    width: 20%;
    ${mq.mqMax('md')}{
        width: 30%;
    }
    ${mq.mqMax('xsm')}{
        width: 42%;
    }
    ${mq.mqMax('xxsm')}{
        width: 90%;
    }
`;
const FooterContact = styled.div`
    width: 20%;
    ${mq.mqMax('md')}{
        width: 30%;
    }
    ${mq.mqMax('xsm')}{
        width: 42%;
    }
    ${mq.mqMax('xxsm')}{
        width: 90%;
    }
`
const FooterSocials = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    .gatsby-image-wrapper{
        margin: 0.5rem 0;
    }
    ${mq.mqMax('md')}{
        order:1;
        .gatsby-image-wrapper{
            width: 20px;
            height: 20px;
        }
    }
    ${mq.mqMax('xsm')}{
        flex-direction: row;
        .gatsby-image-wrapper{
            margin: 0.5rem 0.5rem 0 0;
        }
    }


`
const SiteInfos = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    span:nth-of-type(1){
        color: white;
        font-size: 1.1rem;
        font-weight: 700 !important;
        text-align: center;
    }
    span:nth-of-type(2){
        color: white;
        font-size: 1rem;
        font-weight: 200 !important;
        text-align: center;
        strong{
            font-weight: normal;
        }
        a{
            text-decoration:none;
        }
    }
    ${mq.mqMax('md')}{
        span:nth-of-type(1){
            font-size: 0.9rem;
            font-weight: 700 !important;
        }
        span:nth-of-type(2){
            font-weight: 200 !important;
            font-size: 0.7rem;
        }
    }
`

interface Props {
    lang: string;
}

interface Footer {
    allPrismicFooter: {
        edges: {
            node: {
                lang: string;
                data: {
                    service_title: {
                        raw: RichTextBlock[];
                    };
                    services_links: {
                        raw: RichTextBlock[];
                    };
                    contact_title: {
                        raw: RichTextBlock[];
                    };
                    contact_links: {
                        raw: RichTextBlock[];
                    };
                    description: {
                        raw: RichTextBlock[];
                    };
                    footer_socials: {
                        social_name: string;
                        social_link: {
                            url: string;
                        }
                    }[];
                }
            }
        }[]
    }
}

export const Footer = ({ lang }: Props): JSX.Element => {
    const data = useStaticQuery<Footer>(query);
    const footer = data.allPrismicFooter.edges.map(node => node.node);
    const useSocials = useImages();

    const renderSocials = () =>
        footer?.map((data, i) => {
            if(data?.lang === lang){
                return (
                    <FooterSocials key={i}>
                        {data?.data?.footer_socials?.map((data, i) => (
                        <a href={data.social_link.url} key={i} target="_blank">
                                {useSocials(`footer${data.social_name.toLowerCase()}`, '30px', '30px')}
                        </a>
                        ))}
                    </FooterSocials>
                )
            }
            return;
        })

    const renderServices = () => {
        return footer?.map((data, i) => {
            if(data?.lang === lang){
                
                return (
                    <FooterServices key={i}>
                        <PrismicComponent content={data?.data?.service_title?.raw} />
                        <PrismicComponent content={data?.data?.services_links?.raw} />
                    </FooterServices>
                )
            }
            return;
        })
    };
    const renderContact = () => {
        return footer?.map((data, i) => {
            if (data?.lang === lang) {

                return (
                    <FooterContact key={i}>
                        <PrismicComponent content={data?.data?.contact_title?.raw} />
                        <PrismicComponent content={data?.data?.contact_links?.raw} />
                    </FooterContact>
                )
            }
            return;
        })
    };
    const renderDescription = () => {
        return footer?.map((data, i) => {
            if (data?.lang === lang) {

                return (
                    <FooterDescription key={i}>
                        <PrismicComponent content={data?.data?.description?.raw} />
                    </FooterDescription>
                )
            }
            return;
        })
    };

    return (
        <Root>
            <FooterContainer>
                {renderSocials()}
                {renderDescription()}
                {renderServices()}
                {renderContact()}
            </FooterContainer>
            <SiteInfos>
                <span>© GlobalElectrics {new Date().getFullYear()}</span>
            </SiteInfos>
        </Root>
    );
};

const query = graphql`
query footer{
  allPrismicFooter {
    edges {
      node {
        lang
        data {
          service_title {
            raw
          }
          services_links {
            raw
          }
          contact_title {
            raw
          }
          contact_links {
            raw
          }
          description {
            raw
          }
          footer_socials {
            social_name
            social_link {
              url
            }
          }
        }
      }
    }
  }
}
`

