import { css } from '@emotion/react';
import theme from '@/styles/theme';

const H4 = css`
  font-family: Quicksand, sans-serif;
  color: ${theme.colors.colorGreen};
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
  padding: 0.5rem;
`;

export default H4;
