import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import mq from '@/styles/mq';
import theme from '@/styles/theme';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: `green` | `primary` | `primaryreverse`;
  as?: React.ElementType;
  href?: string;
}

const Root = styled.button<ButtonProps>`
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  a{
    display: block;
    padding: 10px 20px;
  }
  &:hover {
    text-decoration: none;
  }
  ${mq.mqMax('lg')} {
    font-size: 1rem;
  }
`;

export const Button = ({
  children,
  variant = 'primary',
  as = 'button',
  href,
  ...props
}: ButtonProps): JSX.Element => {
  
 const renderVariant = () => {
    switch (variant) {
      case 'green':
        return css`
          color: ${theme.colors.colorGreen};
          background-color: transparent;
          border: 2px solid ${theme.colors.colorGreen};
          &:hover {
            color: ${theme.colors.white};
            background-color: ${theme.colors.colorGreen};
          }
        `;
      case 'primary':
        return css`
          background-color: transparent;
          color: ${theme.colors.colorPrimary};
          border: 2px solid ${theme.colors.colorPrimary};
          &:hover {
            color: ${theme.colors.white};
            background-color: ${theme.colors.colorPrimary};
          }
        `;
      case 'primaryreverse':
        return css`
          background-color: ${theme.colors.colorPrimary};
          color: ${theme.colors.white};
          border: 2px solid ${theme.colors.colorPrimary};
          &:hover {
            color: ${theme.colors.colorPrimary};
            background-color: transparent;
          }
        `;
      default:
        return;
    }
  };

  return (
    <Root {...props} as={as} href={href} css={renderVariant()}>
      {children}
    </Root>
  );
};

