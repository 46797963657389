import React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';

import mq from '@/styles/mq';
import { ContentPresentation, ImagePresentation } from '@/components/PagePresentation';
import theme from '@/styles/theme';

const Root = styled.section`
   display: flex;
   padding: 5rem 0 0 0;
   background-color: ${theme.colors.white};
   ${mq.mqMax('lg')}{
       padding: 4rem 0 0 0;
       flex-direction: column-reverse;

   }
   ${mq.mqMax('md')}{
       padding: 0rem 0 0 0;
       flex-direction: column-reverse;
   }
   
`;


interface Props {
    data: {
        preTitle: RichTextBlock[];
        title: RichTextBlock[];
        content: RichTextBlock[];
        button?: RichTextBlock[];
        image: {
            alt: string;
            fluid: FluidObject;
        }
    }
}


export const PagePresentation = ({ data, ...props }: Props): JSX.Element => {
    const { preTitle, title, content, button, image } = data;
    const { ref, inView } = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    return (
            <Root ref={ref} {...props}>
                <ImagePresentation inView={inView} image={image} />
                <ContentPresentation inView={inView} preTitle={preTitle} title={title} content={content} button={button} />
            </Root>
    );
};
