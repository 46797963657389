import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as cookies from 'js-cookie';
import * as ReactGA from 'react-ga';

import mq from '@/styles/mq';
import { Typography, Link, Button } from '@/components';
import theme from '@/styles/theme';

const Root = styled.div`
  display: flex;
  width: 900px;

  padding: 1rem 2rem;
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 1500;
  background-color: ${theme.colors.white};
  transform: translateY(0%);
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  -moz-box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  ${mq.mqMax('xxxl')}{
      width: 770px;
      font-size: 0.9rem;
      padding: 1rem;
  }
  ${mq.mqMax('lg')} {
    width: 700px;
    flex-direction: column;
    p{
        margin-bottom: 5px;
        display:flex;
        justify-content: center;
        width: 100%;
    }
  }
  ${mq.mqMax('md')} {
    width: 500px;
  }
  ${mq.mqMax('sm600')} {
    width: 90%;
    left: 20px;
    bottom: 20px;
  }
  ${mq.mqMax('xxsm')} {
    left: 10px;
    bottom: 10px;
  }
`;

const bannerLang = {
    'fr': {
        p: 'En visitant ce site web,',
        a: 'vous acceptez notre politique sur l\'utilisation des cookies.',
        button: 'ACCEPTER'
    },
    'en': {
        p: 'By visiting this website,',
        a: 'you agree our policy on the use of cookies.',
        button: 'ACCEPT'
    },
    'nl': {
        p: 'Door deze website te bezoeken,',
        a: 'gaat u akkoord ons beleid inzake het gebruik van cookies.',
        button: 'ACCEPTEREN'
    }
}

interface Props {
    lang: string;
}


export const Cookies = ({ lang }: Props): JSX.Element => {
    const [open, setOpen] = useState<boolean>(true);
    const lg = lang.split('-')[0];
    //@ts-ignore
    const { p, a, button } = bannerLang[lg];

    const ConfirmCookie = () => {
        if (process.env.GATSBY_GOOGLE_ANALYTICS_ID) {
            if (process.env.NODE_ENV === 'production') {
                cookies.set('globalelectrics_legal', 's9j9F2HVJtxG6hXcBH9vJWaq3fCBBIHY', {
                    expires: 365,
                });
                ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS_ID);
                setOpen(false);
            }
        }
    };

    const cookie = cookies.get('globalelectrics_legal');

    useEffect(() => {
        if (cookie) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [cookie]);
    
    return (
        <Root
            css={
                !open && css`
                    transform: translateY(150%);
                    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                `}
        >
                <Typography
                    as="p"
                    css={css`
                    margin: 0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    ${mq.mqMax('md')} {
                        margin: 0.5rem 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                `}
                >
                    {p}
                    <Link
                        to={`/${lg}/cookies`}
                        css={css`
                        display:block;
                        text-transform: lowercase;
                        margin-left: 0.25rem;
                        text-decoration: underline;
                        transition: .3s;
                        &:hover{
                            color: ${theme.colors.colorGreen};
                        }
                        ${mq.mqMax('sm600')} {
                            margin: 0;
                        }
                    `}
                    >
                        {a}
                    </Link>
                </Typography>
            <div
                css={css`
                    text-align: center;
                    @media (max-width: 768px) {
                        margin: 0.5rem 0;
                    }
                `}
            >
                <Button
                    onClick={ConfirmCookie}
                    variant="primary"
                    css={css`
                        padding: 10px 20px;
                        font-size: 1rem;
                        justify-self: flex-end;
                        ${mq.mqMax('xxxl')}{
                            font-size: 0.9rem;
                            padding: 5px 10px;
                        }
                    `}
                >
                    {button}
                </Button>
            </div>
        </Root>
    );
};

