import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RichTextBlock } from 'prismic-reactjs';
import { motion, useAnimation } from 'framer-motion';

import mq from '@/styles/mq';
import PrismicComponent from '@/prismic/prismicComponent';
import { Button } from '@/components';

import theme from '@/styles/theme';

const Root = styled(motion.section)`
   width: 60%;
   padding: 2.5rem 3rem;
   ${mq.mqMax('lg')}{
       width: 100%;
   }
   ${mq.mqMax('md')}{
       padding: 2rem 2.5rem;
   }
`;


interface Props {
    preTitle: RichTextBlock[];
    title: RichTextBlock[];
    content: RichTextBlock[];
    button?: RichTextBlock[];
    inView: boolean;
}


export const ContentPresentation = ({ preTitle, title, content, button, inView, ...props }: Props): JSX.Element => {
    const controls = useAnimation();

    const list = {
        visible: {
            x: 0,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.3,
            },
        },
        hidden: {
            x: 0,
            transition: {
                when: "afterChildren",
            },
        },
    }
    const pretitleAnimation = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }
    const titleAnimation = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }
    const contentAnimation = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }
    const buttonAnimation = {
        visible: { transform: 'scaleY(1)' },
        hidden: { transform: 'scaleY(0)' },
    }



    useEffect(() => {
        if (inView === true) {
            controls.start('visible')
        }
    }, [inView])

    return (
        <Root 
            {...props}
            initial="hidden"
            animate={controls}
            variants={list}
        >
            <motion.div
                variants={pretitleAnimation}
                transition={{ duration: 0.5, times: [0, 0.2, 1] }}
            >
                <PrismicComponent content={preTitle} />
            </motion.div>
            <motion.div
                variants={titleAnimation}
                transition={{ duration: 0.5, times: [0, 0.2, 1] }}
            >
                <PrismicComponent content={title} />
            </motion.div>
            <motion.div
                variants={contentAnimation}
                transition={{ duration: 0.5, times: [0, 0.2, 1] }}
                css={css`
                    a{
                        text-decoration: underline;
                        transition: .3s;
                        &:hover{
                            color: ${theme.colors.colorGreen};
                        }
                }`}
            >
                <PrismicComponent content={content} />
            </motion.div>
            {button !== undefined && <motion.div
                variants={buttonAnimation}
                transition={{ duration: 0.2, times: [0, 0.2, 1] }}
            >
                <Button css={css`
                p{
                    margin: 0;
                }
                ${mq.mqMax('sm')} {
                    p{
                        text-align: center;
                    }
                }
            `}>
                    <PrismicComponent content={button} />
                </Button>
            </motion.div>}
        </Root>
    );
};
