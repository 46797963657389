import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';

import mq from '@/styles/mq';
import PrismicComponent from '@/prismic/prismicComponent';
import { Container } from '@/components';
import { Button } from '@/components';


const Root = styled.section`
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    filter: blur(8px);
    ${mq.mqMax('xxl')} {
        height: 600px;
    }
    ${mq.mqMax('lg')} {
        height: 500px;
        /* height: 1000px; */
    }
`;

const ItemContainer = styled.div`
 background-color:rgba(255,255,255,0.3);
 position: absolute; 
 top: 0;
 left: 0;
 padding: 50px; 
 height: 700px;
 width: 100%;
 z-index: 100;
 display: flex;
 justify-content: center;
 align-items: center;

 p{
     margin: 2rem 0;
 }
 ${mq.mqMax('xxl')} {
        height: 600px;
 }
 ${mq.mqMax('xl')} {
     margin: auto;
    p{
        margin: 1rem 0;
    }
 }
 ${mq.mqMax('lg')} {
    height: 500px;
    /* height: 1000px; */
 }
 ${mq.mqMax('md')} {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    h1{
        margin: 0;
    }
    button{

    }
  }
`

interface Props {
    banner: {
        title: {
            raw: RichTextBlock[];
        };
        content: {
            raw: RichTextBlock[];
        };
        button: {
            raw: RichTextBlock[];
        };
        background_image: {
            alt: string;
            fluid: FluidObject;
        };
    }
}


export const HomeSlider = ({ banner }: Props): JSX.Element => {

    return (
         <div css={css`position: relative;`}>

            <Root css={css`
                background-image: url(${banner.background_image.fluid.src}) ;
            `}>
            </Root>
            <ItemContainer>
                <Container>
                    <PrismicComponent content={banner.title.raw} />
                    <div css={css`p{
                                font-weight:600; 
                                font-size: 1.2rem;
                                ${mq.mqMax('xl')}{
                                    font-size: 1rem;
                                    font-weight: 500;
                                }
                                ${mq.mqMax('xxsm')}{
                                    font-size: 0.9rem;
                                }
                            }`}>
                        <PrismicComponent content={banner.content.raw} />
                    </div>
                    <Button as="button" css={css`
                            p{
                                margin: 0 !important;
                            }
                            ${mq.mqMax('sm')} {
                                p{
                                    text-align: center;
                                }
                            }
                        `}>
                        <PrismicComponent content={banner.button.raw} />
                    </Button>
                </Container>
            </ItemContainer>
        </div>
    );
};
