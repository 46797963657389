import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image';
import { RichTextBlock } from 'prismic-reactjs';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import mq from '@/styles/mq';
import PrismicComponent from '@/prismic/prismicComponent';

const Root = styled(motion.div)`
    padding: 4rem 8rem 4rem 8rem;
    text-align: center;
    ${mq.mqMax('md')}{
        padding: 2rem 3rem 0 3rem;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const imageStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin: 20px;
    ${mq.mqMax('md')}{
        width: 100px;
    }
`

interface Props {
    lang: string;
}

interface Fournisseurs {
    allPrismicFournisseurs: {
        edges: {
            node: {
                lang: string;
                data: {
                    title: {
                        raw: RichTextBlock[];
                    };
                    images_fournisseurs: {
                        image: {
                            alt: string;
                            fluid: FluidObject;
                        }
                    }[]
                }
            }
        }[]
    }
}



export const Fournisseurs = ({ lang }: Props): JSX.Element => {
    const data = useStaticQuery<Fournisseurs>(query);
    const allFournisseurs = data.allPrismicFournisseurs.edges.map(data => data.node);
    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    const variants = {
        visible: (i: number) => ({
            opacity: 1,
            transition: {
                delay: i * 0.1,
            },
        }),
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [inView])

    const renderTitle = () => {
        return allFournisseurs?.map((data, i) => {
            if (data?.lang === lang) {
                return (
                    <PrismicComponent content={data?.data?.title?.raw} />
                )
            }
            return;
        })
    }
    const renderImages = () => {
        return allFournisseurs?.map(data => {
            if(data?.lang === lang){
                return data?.data?.images_fournisseurs?.map((img, i) => (
                        <motion.div
                            key={i}
                            initial="hidden"
                            custom={i}
                            animate={controls}
                            variants={variants}
                            css={imageStyle}
                        >
                                <Img fluid={img?.image?.fluid} alt={img?.image?.alt} css={css`width: 100%; height: auto;`} />
                        </motion.div>
                ))
            }
            return;
        })
    }

    return (
        <Root ref={ref}>
            {renderTitle()}
            <ImageContainer>
                {renderImages()}
            </ImageContainer>
        </Root>
    );
};

export const query = graphql`
query fournisseurs {
  allPrismicFournisseurs {
    edges {
      node {
        lang
        data {
          title {
            raw
          }
          images_fournisseurs {
            image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
}

`
