import React, { forwardRef } from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';

const LinkComponent = (
    { to, target, children, ...rest }: GatsbyLinkProps<Record<string, unknown>>,
    ref: React.LegacyRef<HTMLAnchorElement>,
): JSX.Element => {
    // If no url
    if (!to) return <div />;

    // If internal
    return (
        // @ts-ignore
        <GatsbyLink ref={ref} to={to} target={target} {...rest}>
            {children}
        </GatsbyLink>
    );
};

export const Link = forwardRef(LinkComponent);

