import React from 'react';
import { css } from '@emotion/react';

import mq from '@/styles/mq';
import LogoSvg from '../../svg/GlobalelectricsLogoRed.svg';

export const Logo = (): JSX.Element => {
    return (
            <LogoSvg css={css`
                width: 250px; 
                height: 70px;
                ${mq.mqMax('xl')}{
                    width: 200px;
                    height: 60px;
                }
            `} 
            />
    );
};