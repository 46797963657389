import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image';

import { Link } from '@/components';
import mq from '@/styles/mq';
import PrismicComponent from '@/prismic/prismicComponent';

import theme from '@/styles/theme';

const Root = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    p{
       margin: 10px 0;
       text-align: center; 
       color: white; 
       font-size: 1.2rem;
    }
    ${mq.mqMax('sm')}{
        p{
            font-size: 0.8rem;
        }
    }
`;

const iconStyle = css`
    
    ${mq.mqMin('xl')}{
        width: 120px;
        height: 120px;
    }
    ${mq.mqMax('xl')}{
        width: 100px;
        height: 100px;
    }
    ${mq.mqMax('lg')}{
        width: 80px;
        height: 80px;
    }

    ${mq.mqMax('md')}{
        width: 60px;
        height: 60px;
    }
    ${mq.mqMax('sm')}{
        width: 50px;
        height: 50px;
    }
`;

const linkStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.3333%;
    padding: 4rem 0rem;
    transition: .5s;
    ${mq.mqMax('xl')}{
        padding: 1rem;
    }
    ${mq.mqMax('md')}{
        padding: 2rem 1rem;
    }
    ${mq.mqMax('sm')}{
        padding: 1rem;
    }
    &:hover{
        background-color: ${theme.colors.colorPrimary};
        cursor: pointer;
    }
`;


interface Props {
    title: RichTextBlock[];
    image: {
        alt: string;
        fluid: FluidObject;
    }
    link: {
        id: string;
        link_type: string;
        lang: string;
        uid: string;
        type: string;
    };
}


export const ServiceItem = ({ title, image, link }: Props): JSX.Element => {
    
    const url = link.lang && link.uid ? `/${link.lang.split('-')[0]}/${link.uid}`: '  ';

    return (
        <Link to={url} css={linkStyle}>
            <Root>
                <Img fluid={image.fluid} css={iconStyle} alt={image.alt} />           
                <PrismicComponent content={title} />
            </Root>
        </Link>
    );
};
