import { css } from '@emotion/react';
import mq from '@/styles/mq';

const H2 = css`
  font-family: Quicksand, sans-serif;
  font-size: 1.7em;
  color: #3c3c3b;
  font-weight: 700;
  margin-right: 4px;
  line-height: 1.2;
  margin-bottom: 1rem;
  ${mq.mqMax(`lg`)} {
    font-size: 1.2rem;
  }
`;

export default H2;
