import React from 'react';
import styled from '@emotion/styled';

import mq from '@/styles/mq';

interface RowProps {
    children: React.ReactNode;
}

const Root = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;

  ${mq.mqMax(`md`)} {
    flex-direction: column;
  }
`;

export const Row = ({ children, ...props }: RowProps): JSX.Element => {
    return <Root {...props}>{children}</Root>;
};

