import React, { useEffect } from 'react';
import { FluidObject } from 'gatsby-image';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

import { Button } from '@/components';
import { ServiceItem } from './ServiceItem';
import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';

import theme from '@/styles/theme';


const Root = styled.section`
    display: flex;
    ${mq.mqMax('md')}{
        flex-direction: column;
    }   
`;

const LeftSection = styled(motion.div)`
    width: 40%;
    padding: 2.5rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color:white;
    ${mq.mqMax('md')}{
        width: 100%;
        padding: 2rem 2.5rem;
        button{
            margin: 1rem 0;
        }
    }
`

const RightSection = styled(motion.div)`
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    background-color: ${theme.colors.colorThird};
    ${mq.mqMax('md')}{
        width: 100%;
    }
`

interface Props {
    data: {
        preTitle: RichTextBlock[];
        title: RichTextBlock[];
        button: RichTextBlock[];
        servicesitems: {
            service_title: {
                raw: RichTextBlock[];
            };
            service_link: {
                id: string;
                link_type: string;
                lang: string;
                uid: string;
                type: string;
            };
            service_image: {
                alt: string;
                fluid: FluidObject;
            }
        }[];
    }
}

export const HomePresentation = ({ data }: Props): JSX.Element => {
    const { preTitle, title, button, servicesitems } = data;
    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    
    const list = {
        visible: {
            x: 0,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2,
            },
        },
        hidden: {
            x: 0,
            transition: {
                when: "afterChildren",
            },
        },
    }

    const item = {
        visible: { x: 0 },
        hidden: { x: -700 },
    }

    const ServiceItems = servicesitems?.map((item) => (
                <ServiceItem title={item?.service_title?.raw} image={item?.service_image} link={item?.service_link} />
    ))

    useEffect(() => {
        if (inView === true) {
            controls.start('visible')
        }
    }, [inView])


    return (
        <Root>
            <LeftSection 
                ref={ref} 
                initial="hidden"
                animate={controls}
                variants={list}
            >
                <motion.div
                    variants={item}
                    transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                >
                    <PrismicComponent content={preTitle} />
                </motion.div>
                <motion.div
                    variants={item}
                    transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                >
                    <PrismicComponent content={title} />
                </motion.div>
                <motion.div
                    variants={item}
                    transition={{ duration: 0.3, times: [0, 0.2, 1] }}
                >
                    <Button as="button" css={css`
                                p{
                                    margin: 0 !important;
                                }

                            `}>
                        <PrismicComponent content={button} />
                    </Button>
                </motion.div>
            </LeftSection>
            <RightSection>
                {ServiceItems}
            </RightSection>
        </Root>
    );
};

