import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import mq from '@/styles/mq';

import theme from '@/styles/theme';

const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 2px;
  background-color: ${theme.colors.colorPrimary};
  border-radius: 1px;
  transition: background-color 0.5s;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background-color: ${theme.colors.colorPrimary};
    transform: translateY(-7px);
    transition: transform 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  &::after {
    transform: translateY(7px);
  }
`;

const Root = styled.div`
  position: relative;
  width: 35px;
  height: 35px;

  cursor: pointer;
  z-index: 1070;
  background-color: transparent;
  border-radius: 10%;
  box-shadow: 0 1rem 3rem rgba($black, 0.175);
  ${mq.mqMin('md')} {
    display: none;
  }
`;

interface HeaderTriggerProps {
    open: boolean;
    setOpen: () => void;
}

export const HeaderTrigger = ({ open, setOpen }: HeaderTriggerProps): JSX.Element => {
    const triggerCross = () => {
        switch (open) {
            case true:
                return css`
                    background-color: transparent;
                    &::after {
                        transform: rotate(-135deg);
                    }
                    &::before {
                        transform: rotate(135deg);
                    }
                `;
            case false:
                return css``;
        }
    };

    return (
        <Root onClick={setOpen} css={open && css`position: fixed; top: 10px; right: 10px`}>
            <Hamburger css={triggerCross()} />
        </Root>
    );
};
