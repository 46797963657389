import React from 'react';
import { Elements } from 'prismic-reactjs';
import { css } from '@emotion/react';
import { Link } from '@/components';
import { Typography } from '@/components'; 
const linkResolver = require('@/prismic/resolver');


// -- Function to add unique key to props
const propsWithUniqueKey = function (props: object, key: number) {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer
// This function will be used to change the way the HTML is loaded
// @ts-ignore
export const htmlSerializer = function (type, element, content, children, key) {
  var props = {};

  switch (type) {
    
    // Add a class to paragraph elements

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' };
      return React.createElement('img', propsWithUniqueKey(props, key));

    // Add a class to hyperlinks
    case Elements.hyperlink:

      if (element.data.link_type === 'Document') {
        return (
          <Link key={element.data.id} to={linkResolver(element.data)} >
            {content}
          </Link>
        );
        } else {
          return (
            <a key={element.data.id} href={element.data.url} target="_blank">
              {content}
            </a>
          )
        }
    // Return null to stick with the default behavior
    case Elements.heading1:
      return (
        <Typography
          as="h1"
          variant="h1"
        >
          {children}
        </Typography>
      );
    case Elements.heading2:
      return (
        <Typography
          as="h2"
          variant="h2"
        >
          {children}
        </Typography>
      );
    case Elements.heading3:
      return (
        <Typography
          as="h3"
          variant="h3"
        >
          {children}
        </Typography>
      );
    case Elements.heading4:
      return (
        <Typography
          as="h4"
          variant="h4"
        >
          {children}
        </Typography>
      );
    case Elements.paragraph:
      return (
        <Typography
          as="p"
          css={css``}
        >
          {children}
        </Typography>
      );
    case Elements.listItem: 
        return (
          <li css={css`list-style: circle; margin-left: 1rem; font-weight: 500;`}>
            {children}
          </li>
        )
    case Elements.oListItem:
      return (
        <li css={css`margin: 1rem; font-weight: 500;`}>
          {children}
        </li>
      )

    default:
      return null;
  }
};

//export default htmlSerializer;
