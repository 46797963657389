import React, { useEffect } from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FluidObject } from 'gatsby-image';
import { motion, useAnimation } from 'framer-motion';

import mq from '@/styles/mq';


const Root = styled(motion.section)`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 40%;
   padding: 2.5rem 3rem;
   .gatsby-image-wrapper{
       width: 80%;
       margin: auto;
   }
   ${mq.mqMax('lg')}{
       width: 100%;
       .gatsby-image-wrapper{
            width: 40%;
        }
   }
   ${mq.mqMax('md')}{
       .gatsby-image-wrapper{
            width: 60%;
        }
   }
`;

interface Props {
    image: {
        alt: string;
        fluid: FluidObject;
    }
    inView: boolean;
}


export const ImagePresentation = ({ image, inView, ...props }: Props): JSX.Element => {
    const controls = useAnimation();
    const list = {
        visible: { opacity: 1 },
        hidden: { opacity: 1 },
    }

    const imageAnimation = {
        visible: { transform: 'scale(1)', opacity: 1 },
        hidden: { transform: 'scale(0)', opacity: 0 },
    }
    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [inView])

    return (
        <Root {...props} 
            initial="hidden"
            animate={controls}
            variants={list}
        >
            <motion.div
            css={css`
             width: 100%;
            `}
                variants={imageAnimation}
                transition={{ duration: 0.3, times: [0, 0.2, 1] }}
            >
                <Img fluid={image.fluid} alt={image.alt} />
            </motion.div>
        </Root>
    );
};
