import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import mq from '@/styles/mq';
import { Typography, Textarea, Input } from '@/components';
import theme from '@/styles/theme';

interface InputsContainerProps {
    title: string;
    label: string;
    name: string;
    type?: `input` | `textarea` | string;
    error?: string;
}

interface ErrorMessageProps {
    children: React.ReactNode;
}

const Root = styled.section`
    width: 100%;
    box-sizing: border-box;
    ${mq.mqMax('md')}{
        margin: 0;
    }
`;

const ErrorSpan = styled.span<ErrorMessageProps>`
  font-weight: 400;
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0.5rem;
  color: ${theme.colors.colorError};
  box-sizing: border-box;
`;

const Error = ({ children }: ErrorMessageProps): JSX.Element => (
    <ErrorSpan>{children}</ErrorSpan>
);

export const InputsContainer = ({
    type,
    title,
    label,
    name,
}: InputsContainerProps): JSX.Element => {
    const { formState: { errors } } = useFormContext();

    const renderType = () => {
        switch (type) {
            case 'input':
                return <Input name={name} />;
            case 'textarea':
                return <Textarea name={name} />;
            default:
                return;
        }
    };

    return (
        <Root>
            <Typography
                as="span"
                css={css`
                    color: ${theme.colors.gray500};
                    font-weight: 400;
                    font-size: 0.8rem;
                    display: block;
                    margin-bottom: 0.5rem;
                    box-sizing: border-box;
                    ${mq.mqMax('md')}{
                        font-size: 0.7rem;
                    }
                `}
            >
                {title}
            </Typography>

            <div css={css`display: flex; align-items: center;`}>
                <Typography
                    as="label"
                    css={css`
                    display: block;
                    padding: 0;
                    margin-bottom: 0.5rem;
                    margin-right: 2rem;
                    color: ${theme.colors.colorPrimary};
                    box-sizing: border-box;
                `}
                >
                    {label}
                </Typography>
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <Error>{message}</Error>}
                />
            </div>
            {renderType()}
            
        </Root>
    );
};

