import React from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';

import mq from '@/styles/mq';
import theme from '@/styles/theme';
import PrismicComponent from '@/prismic/prismicComponent';
import { Button } from '@/components';

const Root = styled.section`
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    -moz-box-shadow:rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    width: 250px;
    height: 200px;
    @keyframes fade-in {
            0% {
                opacity: 0;
                transform: scale(0);
            }

            100% {
                opacity: 1;
                transform: scale(1);
            }
            }

    animation: .3s ease-in-out both fade-in;
    &:hover{
        cursor: pointer;
    }
    &:hover img .service_text{
        opacity: 0;
        transition: opacity .5s;
    }
    &:hover .back {
        opacity: 1;
        transition: opacity .5s;
    }
    ${mq.mqMax('md')}{
        width: 200px;
        height: 150px;
    }

`;

const TextContainer = styled.div`
    width: 100%;
    height: 35px;
    position: absolute;
    bottom: 0;
    background-color: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: transform .5s;
    transform-style: preserve-3d;
`

const BackContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
`

const imageStyle = css`
    width: 250px;
    height: 200px;
    ${mq.mqMax('md')}{
        width: 200px;
        height: 150px;
    }
`
interface Props {
    title: RichTextBlock[];
    button: RichTextBlock[];
    image: {
        alt: string;
        fluid: FluidObject;
    };
}


export const Service = ({ title, image, button, ...props }: Props): JSX.Element => {

    return (
            <Root {...props}>
                <Img fluid={image.fluid} alt={image.alt} css={imageStyle} />
                <TextContainer className="service_text">
                    <PrismicComponent content={title} />
                </TextContainer>
                <BackContainer className="back">
                    <Button css={css`margin: 10px 0; p{margin: 0;}`} variant="green">
                        <PrismicComponent content={button} />
                    </Button>
                </BackContainer>
            </Root>
    );
};
