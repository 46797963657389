import { css } from '@emotion/react';
import mq from '@/styles/mq';

const Text = css`
  font-family: Quicksand, sans-serif;
  margin-bottom: 1rem;
  font-weight: 500;
  ${mq.mqMax(`lg`)} {
    font-size: 0.9rem;
  }
  
`;

export default Text;
