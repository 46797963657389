import { css } from '@emotion/react';
import mq from '@/styles/mq';

const H1 = css`
  font-family: Quicksand, sans-serif;
  box-sizing: border-box;
  color: #3c3c3b;
  font-size: 2.3rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-transform: uppercase;
  ${mq.mqMax(`xxxl`)} {
    font-size: 2rem;
  }
  ${mq.mqMax(`xl`)} {
    font-size: 1.7rem;
  }
  ${mq.mqMax(`lg`)} {
    font-size: 1.5rem;
  }
`;

export default H1;
