import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';

import { HomeSlider } from '@/components';

import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Autoplay, EffectFade]);

const Root = styled.div`
    .swiper-container{
        z-index: 0 !important;
    }
`;

interface Props {
    banner: {
        background_image: {
            alt: string;
            fluid: FluidObject;
        };
        button: {
            raw: RichTextBlock[];
        };
        content: {
            raw: RichTextBlock[];
        };
        title: {
            raw: RichTextBlock[];
        };
    }[];
}


export const Carousel = ({ banner }: Props): JSX.Element => {

    return (
        <Root>
            <Swiper
                autoplay={{delay: 7000}}
                loop
                slidesPerView={1}
                effect="fade"
            >
               {banner?.map((data) => (
                    <SwiperSlide>
                        <HomeSlider banner={data} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </Root>
    );
};
