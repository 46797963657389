import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import styled from '@emotion/styled';

import { Logo, Link } from '@/components';
import { HeaderActions } from '@/components/Header';
import mq from '@/styles/mq';
import theme from '@/styles/theme';


const Root = styled.header`
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  /* background-color: ${theme.colors.white}; */
/* ok */ 
/* background: rgb(2,22,47);
background: linear-gradient(180deg, rgba(2,22,47,0.4822303921568627) 0%, rgba(14,52,99,0.3253676470588235) 37%, rgba(255,255,255,0.10968137254901966) 100%); */

background: rgb(2,22,47);
background: linear-gradient(180deg, rgba(2,22,47,0.4822303921568627) 0%, rgba(14,52,99,0.3253676470588235) 37%, rgba(156,171,191,0.19931722689075626) 74%, rgba(255,255,255,0.10968137254901966) 100%);

  ${mq.mqMax('xl')}{
      padding: 16px;
  }
  ${mq.mqMax('lg')}{
    flex-direction: column;
  }
`;

interface Props {
  lang: string;
  header: {
    email: {
      raw: RichTextBlock[];
    };
    phone: {
      raw: RichTextBlock[];
    };
    socials: {
      social: string;
      link: {
        url: string;
      }
    }[];
  }
}

export const Header = ({ header, lang }: Props): JSX.Element => {
  const lg = lang.split('-')[0];
  
  return (
    <Root>
      <Link to={`/${lg}`}>
        <Logo />
      </Link>
      <HeaderActions header={header} />
    </Root>
  );
};
