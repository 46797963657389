import React, { useEffect } from 'react';
import { graphql, useStaticQuery  } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';
import { FluidObject } from 'gatsby-image';

import mq from '@/styles/mq';

import PrismicComponent from '@/prismic/prismicComponent';
import { Service } from '@/components/Services';
import { Container } from '@/components';

const Root = styled.div`
  padding: 8rem 2rem;
  ${mq.mqMax('lg')}{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const ServiceContainer = styled(motion.section)`
  display: flex;
  flex-wrap: wrap;
  ${mq.mqMax('lg')}{
    justify-content: center;
  }
`


interface Props extends JSX.IntrinsicAttributes {
  lang: string;
}

interface ServiceType {
  allPrismicServices: {
    edges: {
      node: {
        lang: string;
        data: {
          nos_services: {
            service_title: {
              raw: RichTextBlock[];
            }
            service_button: {
              raw: RichTextBlock[];
            };
            service_image: {
              alt: string;
              fluid: FluidObject;
            };
          }[];
          primary_title: {
            raw: RichTextBlock[];
          };
        }
      }
    }[]
  }
}


export const Services = ({ lang }: Props): JSX.Element => {
    const data = useStaticQuery<ServiceType>(query);
    const allServices = data?.allPrismicServices?.edges?.map(data => data.node);
    const controls = useAnimation();

    const { ref, inView } = useInView({
      threshold: 0.5,
      triggerOnce: true,
    });

  const variants = {
    visible: (i: number) => ({
      opacity: 1,
      transform: 'scale(1)',
      transition: {
        delay: i * 0.2,
      },
    }),
    hidden: { opacity: 0, transform: 'scale(0)'},
  }

  useEffect(() => {
    if(inView){
      controls.start('visible')
    }
  }, [inView])

  const renderTitle = () => {
    return allServices?.map(data => {
      if (data?.lang === lang) {
        return <PrismicComponent content={data?.data?.primary_title?.raw} />
      }
      return;
    })
  }

  const renderServices = () => {
    return allServices?.map(data => {
      if (data?.lang === lang) {
        return data?.data?.nos_services?.map((data, i) => (
          <motion.div
            key={i}
            initial="hidden"
            custom={i}
            animate={controls}
            variants={variants}
          >
            <Service title={data?.service_title?.raw} button={data?.service_button?.raw} image={data?.service_image} />
          </motion.div>
        ))
      }
      return;
    })
  }


    return (
        <Container>
          <Root ref={ref}>
              {renderTitle()}
              <ServiceContainer>
                {renderServices()}
              </ServiceContainer>
            </Root>
        </Container>
    );
};


const query = graphql`
query services {
  allPrismicServices {
    edges {
      node {
        data {
          nos_services {
            service_title {
              raw
            }
            service_image {
              alt
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            service_button {
              raw
            }
          }
          primary_title {
            raw
          }
        }
        lang
      }
    }
  }
}
`