import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import mq from '@/styles/mq';
import { SelectLanguage } from '@/components';
import { HeaderTrigger } from '@/components/Header';
import PrismicComponent from '@/prismic/prismicComponent';
import { RichTextBlock } from 'prismic-reactjs';
import theme from '@/styles/theme';


const Root = styled.nav`
    display: flex;
    position: -webkit-sticky;
    position: sticky !important;
    top:0;
    z-index: 500;
    width: 100%;
    padding: 16px 16px;
    background-color: ${theme.colors.white};

    box-shadow: 0px 3px 5px -4px rgba(0,0,0,0.76);
    -webkit-box-shadow: 0px 3px 5px -4px rgba(0,0,0,0.76);
    -moz-box-shadow: 0px 3px 5px -4px rgba(0,0,0,0.76);

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    p{
        font-weight: normal;
    }
    ul{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        li{
            margin: 0 16px;
            transition: .3s;
            position: relative;
            &:hover{
                color: ${theme.colors.colorPrimary};
            }
            &:hover .active-circle{
                opacity: 0.7;
            }
            p, a{
                font-size: 1rem;
            }
        }
    }
    ${mq.mqMax('xl')}{
        li{
            margin: 0 6px !important;
            p, a{
                font-size: 0.9rem !important;
            }
        }
    }
    ${mq.mqMax('lg')}{
        li{
            margin: 0 7px !important;
            p, a{
                font-size: 0.7rem !important;
            }
        }
    }
    ${mq.mqMax('md')}{
        padding: 8px !important;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: center;
        background-color: ${theme.colors.white};
        ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: fixed !important;
            height: 100% !important;
            width: 100% !important;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: ${theme.colors.white};
            transform: translateX(100%);
            opacity: 1;
            transition: opacity 0.5s cubic-bezier(1, 0, 0, 1);
            li{
                margin: 10px 0 !important;
                p, a{
                    font-size: 0.9rem !important;
                }
            }

        }
    }
`;



const ActiveCircle = styled.div`
    position: absolute;
    right: 50%;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    margin: auto;
    transition: .2s;
    background-color: rgba(14, 52, 99, 0.85);
    opacity: 0;
`


interface Props {
    open: boolean;
    toggleNav: () => void;
    navigation: {
        nav_link: {
            raw: RichTextBlock[];
        }
    }[];
    selectLang: {
        lang: string;
        uid: string;
        alternate_languages: {
            lang: string;
            uid: string;
        }[];
    };
    isHome?: boolean;
}


export const Nav = ({ open, toggleNav, navigation, selectLang, isHome }: Props): JSX.Element => {

    const openMenu = () => {
        switch (open) {
            case true:
                return css`
                ${mq.mqMax('md')} {
                        background-color: transparent;
                        box-shadow: none;
                        border: 0;
                        
                        ul{
                            transform: translateX(0);
                            transition: transform 0.5s cubic-bezier(1, 0, 0, 1);
                        }
                    }
                `;
                    case false:
                        return css`
                ${mq.mqMax('md')} {
                    ul{
                        transform: translateX(100%);
                        transition: transform 0.5s cubic-bezier(1, 0, 0, 1);
                    }
                }
            `;
            default:
                return css``;
        }
    };
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  return (
      <Root css={openMenu()}>
          <HeaderTrigger open={open} setOpen={toggleNav} />
          <ul css={css`p{margin: 0;}`}>
              {navigation?.map((data, i) => {
                  const { nav_link } = data;
                  const { spans } = nav_link.raw[0];
                  let url;
                  if(spans && spans?.length > 0){
                      const { data } = spans[0];
                      let lang;
                      if (data?.lang) {
                          lang = data.lang.split('-')[0]
                      }
                      data?.type === 'homepage' ? url = `/${lang}` : url = `/${lang}/${data?.uid}`;
                  }
                  return(
                      <li key={i}>
                          <PrismicComponent content={data?.nav_link?.raw} key={i} />
                          {url === pathname && <ActiveCircle css={css`opacity: 1;`} />}
                          <ActiveCircle className="active-circle" />
                      </li>
                  )
              })}
          </ul>

          {!open && <SelectLanguage selectLang={selectLang} isHome={isHome} />}
      </Root>
  );
};
