import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react';


interface Props {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}


export const ModalGallery = ({ isOpen, onClose, children }: Props): JSX.Element => {
    return(
        <Modal motionPreset="scale" onClose={onClose} isOpen={isOpen} isCentered size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody pt={12} pl={0} pr={0} pb={10}>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}