import { css } from '@emotion/react';
import theme from '@/styles/theme';

const H3 = css`
  font-family: Quicksand, sans-serif;
  color: ${theme.colors.colorAccent};
  /* font-size: 1.3rem; */
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0.5rem 0;
`;

export default H3;
