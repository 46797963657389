import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';

import mq from '@/styles/mq';
import PrismicComponent from '@/prismic/prismicComponent';

import theme from '@/styles/theme';

const Root = styled(motion.section)`
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background-color: ${theme.colors.colorThird};
    box-sizing: border-box;
    h2{
        color: ${theme.colors.colorGreen};
    }
    > * {
        color: ${theme.colors.white};
    }
    ${mq.mqMax('md')}{
        flex-direction: column; 
        padding: 2rem;
    }

`;

const Section = styled(motion.section)`
    width: 25%;
    padding: 0 3rem;
    ${mq.mqMax('xxl')}{
        width: 30%;
    }
    ${mq.mqMax('md')}{
        width: 100%;
        padding: 3rem 0;
    }
`;
const MiddleSection = styled(motion.section)`
    width: 25%;
    padding: 0 3rem;
    border-right: 0.2px solid ${theme.colors.colorGreen};
    border-left: 0.2px solid ${theme.colors.colorGreen};
    ${mq.mqMax('xxl')}{
        width: 30%;
    }
    ${mq.mqMax('md')}{
        width: 100%;
        padding: 3rem 0;
        border-right: 0;
        border-left: 0;
        border-top: 0.2px solid ${theme.colors.colorGreen};
        border-bottom: 0.2px solid ${theme.colors.colorGreen};
    }
`;



interface Props {
    data: {
        content: {
            raw: RichTextBlock[];
        };
        content2: {
            raw: RichTextBlock[];
        };
        content3: {
            raw: RichTextBlock[];
        };
    }
}


export const InstallationPresentation = ({ data, ...props }: Props): JSX.Element => {
    const { content, content2, content3 } = data;
    const { ref, inView } = useInView({
        threshold: 0.4,
        triggerOnce: true,
    });


    const controls = useAnimation();

    const list = {
        visible: {
            x: 0,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0,
            },
        },
        hidden: {
            x: 0,
            transition: {
                when: "afterChildren",
            },
        },
    }

    const leftAnimation = {
        visible: { x: 0 },
        hidden: { x: -1000 },
    }
    const middleAnimation = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }
    const rightAnimation = {
        visible: { x: 0 },
        hidden: { x: 1000 },
    }

    useEffect(() => {
        if (inView === true) {
            controls.start('visible')
        }
    }, [inView])


    return (
        <Root  
            {...props}
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={list}
        >
            <Section
                variants={leftAnimation}
                transition={{ duration: 0.5, times: [0, 0.2, 1] }}
            >
                <PrismicComponent content={content.raw}/>
            </Section>
            <MiddleSection
                variants={middleAnimation}
                transition={{ duration: 1, times: [0, 0.2, 1] }}
            >
                <PrismicComponent content={content2.raw} />
            </MiddleSection>
            <Section
                variants={rightAnimation}
                transition={{ duration: 0.5, times: [0, 0.2, 1] }}
            >
                <PrismicComponent content={content3.raw} />
            </Section>
        </Root>
    );
};
