import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import styled from '@emotion/styled';
import { RichTextBlock } from 'prismic-reactjs';

import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';
import theme from '@/styles/theme';

const Root = styled(motion.section)`
   background-color: ${theme.colors.colorPrimary};
   width: 60%;
   margin: auto;
   margin-top: 3rem;
   margin-bottom: 1.5rem;
   padding: 2rem;
   border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;


   h1, h3{
       color: ${theme.colors.colorSuccess};
       padding: 0;
   }

   p{
       color: ${theme.colors.white};
   }
   ${mq.mqMax('xl')}{
       width: 80%;
       margin-top: 1.5rem;
       margin-bottom: 0.5rem;
   }
   ${mq.mqMax('md')}{
       padding: 1rem;
       width: 80%;
       margin-top: 1rem;
       margin-bottom: 0.5rem;
       h1{
           margin-bottom: 0.5rem;
       }
   }
   ${mq.mqMax('sm')}{
       width: 90%;
   }
`;


interface Props {
    content: {
        raw: RichTextBlock[];
    }
}


export const BannerDepannage = ({ content, ...props }: Props): JSX.Element => {
    
    const { ref, inView } = useInView({
        threshold: 0.3,
        triggerOnce: true,
    });

    const controls = useAnimation();

    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [inView])

    return (
        
        <Root {...props}
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={variants}
            transition={{ duration: 0.7, times: [0, 0.2, 1] }}
        >
            <PrismicComponent content={content.raw} />
        </Root>
        
    );
};
