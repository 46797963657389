import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { FluidObject } from 'gatsby-image';
import { RichTextBlock } from 'prismic-reactjs';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styled from '@emotion/styled';

import { Gallery } from '@/components/Galleries';
import { Container, ModalGallery } from '@/components';
import PrismicComponent from '@/prismic/prismicComponent';
import mq from '@/styles/mq';


import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';

SwiperCore.use([Navigation]);

const Root = styled.section`
  display: flex;
  flex-direction: column-reverse;

`;

const SliderItem = styled.div`
    padding: 0rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 80vh !important;

    .gatsby-image-wrapper{
        width: 100%;
        height: auto;
    }
    ${mq.mqMax('lg')}{
        padding: 0rem 3rem !important;
    }
    ${mq.mqMax('xsm')}{
        padding: 0rem 2.2rem;
    }
`


interface Props {
    lang: string;
}

interface GalleryType {
    allPrismicGallery: {
        edges: {
            node: {
                lang: string;
                data: {
                    gallery_title: {
                        raw: RichTextBlock[];
                    };
                    gallery: {
                        images_gallery: {
                            alt: string;
                            fluid: FluidObject;
                        };
                    }[]
                };
            };
        }[]
    };
}

export const Galleries = ({ lang }: Props): JSX.Element => {
    const data = useStaticQuery<GalleryType>(query);
    const galleries = data.allPrismicGallery.edges.map(node => node.node);
    const [isOpen, setIsopen] = useState<boolean>(false);
    const [index, setIndex] = useState<number>();
    const [swiper, setSwiper] = useState(null);
    const [images, setImages] = useState(galleries[0].data.gallery);

    useEffect(() => {
        if (swiper) {
            //@ts-ignore
            swiper.slideTo(index);
        }
    }, [swiper]);

    const renderGalleries = () => {
        return galleries?.map(data => {
            if(data.lang === lang){
                return (
                    <Container>
                        <PrismicComponent content={data?.data?.gallery_title?.raw} />
                        <Gallery images={data?.data?.gallery} setOpen={setIsopen} setIndex={setIndex} setImages={setImages} />
                    </Container>
                )
            }
            return;
        })
    }

    return (
        <Root>
            {renderGalleries()}
            {isOpen && <div>
                <ModalGallery isOpen={isOpen} onClose={() => setIsopen(!isOpen)}>
                    <Swiper
                        slidesPerView={1}
                        navigation
                        onSwiper={(s) => {
                            //@ts-ignore
                            setSwiper(s);
                        }}
                    >
                        {images?.map(img => (
                            <SwiperSlide>
                                <SliderItem>
                                    <Img fluid={img?.images_gallery?.fluid} alt={img?.images_gallery?.alt} />
                                </SliderItem>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </ModalGallery>
                </div>}
        </Root>
    );
};

const query = graphql`
    query MyQuery {
        allPrismicGallery {
            edges {
            node {
                data {
                gallery_title {
                    raw
                }
                gallery {
                    images_gallery {
                    alt
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                    }
                }
                }
                lang
            }
            }
        }
    }
`