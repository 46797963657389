import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import styled from '@emotion/styled';

import { HeaderCall } from '@/components/Header';
import { HeaderMail } from '@/components/Header';
import { HeaderSocials } from '@/components/Header';
import mq from '@/styles/mq';


const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  ${mq.mqMax('xl')}{
    justify-content: center;
  }
  ${mq.mqMax('lg')}{
    margin-top: 20px;
    justify-content: space-around;
    
  }
  ${mq.mqMax('sm')}{
    margin: 0;
    flex-direction: column;
    align-items: center;
  }

`;

interface Props {
  header: {
    email: {
      raw: RichTextBlock[];
    };
    phone: {
      raw: RichTextBlock[];
    };
    socials: {
      social: string;
      link: {
        url: string;
      }
    }[];
  }
}

export const HeaderActions = ({ header }: Props): JSX.Element => {
    const { email, phone, socials } = header;
    
    return (
        <Root>
            <HeaderSocials socials={socials} />
            <HeaderMail email={email} />
            <HeaderCall phone={phone} />
        </Root>
    );
};
