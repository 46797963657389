import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import * as cookies from 'js-cookie';
import { Link } from '@/components';

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react"

import useImages from '@/hooks/useImages';

interface Props {
    selectLang: {
        lang: string;
        uid: string;
        alternate_languages: {
            lang: string;
            uid: string;
        }[];
    };
    isHome?: boolean;
}


export const SelectLanguage = ({ selectLang, isHome }: Props): JSX.Element => {
    const flags = useImages();

    const { lang, alternate_languages } = selectLang;
    const language = lang.split('-')[0];
    useEffect(() => {
        cookies.set('favorite_language', lang.split('-')[0]);
    }, [lang])
    return (
        <Menu>
            <MenuButton as="button" css={css`
                align-self: center;
                outline: none;
            `}>
                {flags(language, '20px', '20px')}
            </MenuButton>
            <MenuList css={css`min-width: 6%;`}>
                {alternate_languages?.map((data, i) => {
                    const lang = data?.lang.split('-')[0];
                    const uid = data?.uid;
                    const url = `/${lang}/${uid}`;

                    return(
                        <Link to={isHome === true ? `/${lang}`: url} key={i}>
                            <MenuItem css={css`padding: 4px`} >{flags(lang, '20px', '20px')}</MenuItem>
                        </Link>
                    )
                })}
            </MenuList>
        </Menu>
    );
};

