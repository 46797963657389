import React from 'react';
import { css } from '@emotion/react';
import { Container as ChakraContainer, ContainerProps } from '@chakra-ui/react';

import mq from '@/styles/mq';
interface CProps extends ContainerProps {
    children: React.ReactNode;
}

const containerStyle = css`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  ${mq.mqMin('sm')} {
    max-width: 540px;
  }
  ${mq.mqMin('md')} {
    max-width: 720px;
  }
  ${mq.mqMin('lg')} {
    max-width: 960px;
  }
  ${mq.mqMin('xl')} {
    max-width: 1240px;
  }
`;

export const Container = ({ children, ...props }: CProps): JSX.Element => {
    return (
        <ChakraContainer css={containerStyle} {...props}>
            {children}
        </ChakraContainer>
    );
};
