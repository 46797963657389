import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import styled from '@emotion/styled';

import mq from '@/styles/mq';
import { Link } from '@/components';
import PrismicComponent from '@/prismic/prismicComponent';
import useImages from '@/hooks/useImages';

import theme from '@/styles/theme';


const Root = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4rem;
  transition: .3s;
  a{
      margin-left: 10px;
  }
  p{
      margin: 0;
  }
  &:hover{
      color: ${theme.colors.colorPrimary};
  }
  ${mq.mqMax('xl')}{
        a{
            font-size: 0.9rem !important;
            margin-left: 5px;
        }
        .gatsby-image-wrapper{
            width: 20px;
            height: 20px;
        }
    }
 ${mq.mqMax('lg')}{
    margin: 0;
  }
  ${mq.mqMax('sm')}{
    margin-top: 10px;
  }
`;

interface Props {
    email: {
        raw: RichTextBlock[];
    };
}

export const HeaderMail = ({ email }: Props ): JSX.Element => {
    const mailImage = useImages();
    return (
        <Root>
            {mailImage('mail')}
            <PrismicComponent content={email.raw} />
        </Root>
    );
};

